<div class="remote-bar">
    <div class="server-back button" (click)="backToServerSelection()"><i class="fa fa-arrow-left"></i></div>
    <div class="server-name">{{serverId == -1 ? 'All' : curServersShortcuts.length ? curServersShortcuts[0].serverName :
        '-'}}</div>
    <div class="server-state">
        <span class="socket-state" [ngClass]="{
                'yellow': socketState === 1 || socketState === 4,
                'orange': socketState === 2,
                'green': socketState === 3
            }">
            <span class="icon"><i class="fa fa-server"></i></span>
            <span>{{ socketStateLabel[socketState] }}</span>
        </span>
    </div>
</div>

<div class="shortcut-server-list" [class.reduced]="statusBarShown">
    <div class="shortcut-list" [class.solo]="serverId != -1" *ngFor="let serverShortcuts of serversShortcutsActive">
        <div *ngIf="serverId == -1" class="shortcut-server-topbar">{{serverShortcuts.serverName}}</div>
        <app-remote-button *ngFor="let shortcut of serverShortcuts.vmixShortcuts" [serverId]="serverShortcuts.serverId"
            [shortcut]="shortcut" [buttonRatio]="buttonRatio"
            [shortcutStatusClass]="getShortStatusClass(serverShortcuts.serverId, shortcut.id)"
            (onclick)="clickShortcut($event)"></app-remote-button>
    </div>
</div>

<div class="tool-bar">
    <div class="tb-topbar">
        <div class="tbt-tabs">
            <div class="tb-tab" [class.active]="curTool == 'titles'" (click)="selectTool('titles')">Titles</div>
            <div class="tb-tab" [class.active]="curTool == 'status'" (click)="selectTool('status')">Status</div>
        </div>
        <div class="tb-close" (click)="toggleToolBar()"><i class="fa fa-times"></i></div>
    </div>
    <div class="tb-container">
        <div class="status-container" *ngIf="curTool == 'status'">
            <div class="status-group" *ngFor="let statusServer of curServersStatus">
                <div class="status-item" *ngFor="let status of getStatusString(statusServer.serverId)">
                    <label title="{{status.statusLabel}}"><span>{{status.statusLabel}}</span></label>
                    <div class="status-value">
                        <span
                            [ngClass]="getStatusStringClass(status.statusValue)"><span>{{status.statusValue}}</span></span>
                    </div>
                </div>
                <div class="status-item" *ngFor="let status of getStatusPercent(statusServer.serverId)">
                    <label title="{{status.statusLabel}}"><span>{{status.statusLabel}}</span></label>
                    <div class="status-value">
                        <span class="progress-bar"><span class="progression"
                                [style.width]="status.statusValue"></span><span
                                class="value">{{status.statusValue}}</span></span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="titles-container" *ngIf="curTool == 'titles'">
            <div class="titles-group" *ngFor="let serverPresetsTypes of curServersPresetsTypes"
                [style.width]="'calc(100% / '+ curServersPresetsTypes.length +')'">
                <div class="title-selector">
                    <select #presetTypeSelect
                        (change)="selectPresetType(serverPresetsTypes.serverId, presetTypeSelect.value)">
                        <option *ngFor="let titlePresetTypes of serverPresetsTypes.titlePresetTypes"
                            [value]="titlePresetTypes.titlePresetTypeId">{{titlePresetTypes.title}}</option>
                    </select>
                </div>
                <h1>Live</h1>
                <div class="input-list" *ngIf="isLoadingPreset">
                    <div class="input-grp">
                        Loading...
                    </div>
                </div>
                <div class="input-list" *ngIf="!isLoadingPreset">
                    <div class="input-grp"
                        *ngFor="let presetDetLive of getCurPresetsLive(serverPresetsTypes.serverId); index as i">
                        <label>{{presetDetLive.name}}</label>
                        <span class="cur-value" [title]="presetDetLive.value">{{presetDetLive.value.trim().length ?
                            presetDetLive.value : '-'}}</span>
                        <div class="change">
                            <input type="text"
                                [(ngModel)]="editCurSrvPresetLive[serverPresetsTypes.serverId].titlePresetDetails[i].value"
                                placeholder="New value...">
                            <button
                                (click)="loadPresetValues(editCurSrvPresetLive[serverPresetsTypes.serverId].titlePresetDetails[i], presetDetLive.value)"><i
                                    class="fa fa-download"></i></button>
                        </div>

                    </div>
                </div>
                <div class="actions-live" *ngIf="!isLoadingPreset">
                    <button (click)="applyPresetLive(serverPresetsTypes.serverId)">Apply</button>
                    <button (click)="savePreset(serverPresetsTypes.serverId)">Add Preset</button>
                </div>
                <h1>Presets</h1>
                <div class="preset-list">
                    <div class="preset-item"
                        *ngFor="let savedPreset of getCurSavedPresets(serverPresetsTypes.serverId)">
                        <div class="actions">
                            <button (click)="loadFromSavedPreset(serverPresetsTypes.serverId, savedPreset)"><i
                                    class="fa fa-arrow-circle-up"></i></button>
                            <button (click)="removePreset(savedPreset.titlePresetId)"><i
                                    class="fa fa-times"></i></button>
                        </div>
                        <div class="values">{{getPresetValuesList(savedPreset)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="toogle-tool-bar-bt" *ngIf="!statusBarShown">
    <div class="bt" (click)="toggleToolBar()"><i class="fa fa-tools"></i></div>
</div>

<div class="zoom-bt-grp">
    <div class="bt left" (click)="increaseBtRatio()"><i class="fa fa-plus"></i></div>
    <div class="bt" (click)="setBtRatio(0)"><i class="fa fa-undo"></i></div>
    <div class="bt right" (click)="decreaseBtRatio()"><i class="fa fa-minus"></i></div>
</div>
import { Component, OnInit } from '@angular/core';
import { Company, Server, User, UserServerRights } from '@app/models';
import { ToastService } from '@app/services/toast.service';
import { VmixAdminApiService } from '@app/services/vmix-admin-api.service';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss']
})
export class AdminUserComponent implements OnInit {
  usrSrvRightPpShown: boolean;
  companiesPpShown: boolean;
  isLoadingUsers: boolean;
  users: User[];
  isLoadingCompanies: boolean;
  isSavingCompany: boolean;
  isDeletingCompany: boolean;
  companies: Company[];
  curCompany: Company;

  isLoadingUser: boolean;
  isSavingUser: boolean;
  isDeletingUser: boolean;
  isLoadingUserServerRights: boolean;
  isLoadingServers: boolean;
  isSavingUserServerRight: boolean;
  isDeletingUserServerRight: boolean;
  disabledResetPassword: boolean;
  curUser: User;
  private curUserServerRights: UserServerRights[];
  curUserServerRight: UserServerRights;
  servers: Server[];
  userRightsFilter: string;
  usrAlwaysActive: boolean;
  userFilter: string;

  constructor(
    private toastService: ToastService,
    private vmixAdminApiService: VmixAdminApiService 
  ) { }

  ngOnInit(): void {
    this.usrSrvRightPpShown = false;
    this.companiesPpShown = false;
    this.isLoadingUsers = false;
    this.isLoadingUser = false;
    this.isSavingUser = false;
    this.isDeletingUser = false;
    this.isLoadingUserServerRights = false;
    this.isLoadingServers = false;
    this.isSavingUserServerRight = false;
    this.isDeletingUserServerRight = false;
    this.isLoadingCompanies = false;
    this.isSavingCompany = false;
    this.isDeletingCompany = false;
    this.disabledResetPassword = false;
    this.usrAlwaysActive = true;
    this.companies = [];

    this.userRightsFilter = '';
    this.userFilter = '';

    this.clearUsers();
    this.clearCurUser();
    this.clearCurCompany();
    this.clearUserServerRights();
    this.clearCurUserServerRight();

    this.getCompanies();
    this.getUsers();
    this.getServers();
  }

  clearUsers() {
    this.users = [];
  }

  clearCurUser() {
    this.curUser = null;
  }

  clearCurCompany() {
    this.curCompany = null;
  }

  private getCompanies() {
    this.isLoadingCompanies = true;
    this.clearUsers();
    this.vmixAdminApiService.getCompanies().subscribe((companies) => {
      console.log(companies);
      if (companies) {
        this.companies = companies;
      }
      this.isLoadingCompanies = false;
    },
    (err) => {

    });
    
  }

  get usersFiltred(): User[] {
    let result = [];
    let companies = [];
    for (const user of this.users) {

      if ((!this.userFilter.trim().length)
          || user.firstName.toUpperCase().includes(this.userFilter.trim().toUpperCase())
          || user.lastName.toUpperCase().includes(this.userFilter.trim().toUpperCase())
          || user.emailAddress.toUpperCase().includes(this.userFilter.trim().toUpperCase())
          || user.company.companyName.toUpperCase().includes(this.userFilter.trim().toUpperCase())) {

        let compId = user.company.companyId;
        let i = companies.indexOf(compId);
        if(i<0) {
          let newComp = {
            companyName: user.company.companyName,
            users: [user]
          }
          companies.push(user.company.companyId)
          result.push(newComp);
          
        } else {
          result[i].users.push(user)
        }
      }
    }
    
    result.sort((a, b)=>{
      return a.companyName.localeCompare(b.companyName)
    })
    
    return result;
    
  }

  private getUsers() {
    this.isLoadingUsers = true;
    this.clearUsers();
    this.vmixAdminApiService.getUsers().subscribe((users) => {
      console.log(users);
      if (users) {
        this.users = users;
      }
      this.isLoadingUsers = false;
    },
    (err) => {

    });
  }

  private getUser(aUserId: number) {
    this.isLoadingUser = true;
    this.clearCurUser();
    this.vmixAdminApiService.getUser(aUserId).subscribe((user) => {
      if (user) {
        this.curUser = user
      }
      this.isLoadingUser = false;
    },
    (err) => {

    });
  }

  private addUser(aUser: User) {
    this.isSavingUser = true;
    this.vmixAdminApiService.addUser(aUser).subscribe((user) => {
      if (user) {
        this.users.push(Object.assign({}, user));
        this.curUser = user;
        this.selectUser(user.userId);
        this.toastService.pushSucess('User saved');
      }
      this.isSavingUser = false;
    },
    (err) => {

    });
  }

  private updateUser(aUser: User) {
    this.isSavingUser = true;
    this.vmixAdminApiService.updateUser(aUser).subscribe((user) => {
      if (user) {
        for (let index = 0; index < this.users.length; index++) {
          const usr = this.users[index];
          if(usr.userId == user.userId) {
            this.users.splice(index, 1, Object.assign({}, user))
            break;
          }
        }
        this.curUser = user;
        this.toastService.pushSucess('User saved');
      }
      this.isSavingUser = false;
    },
    (err) => {

    });
  }

  private deleteUser(aUserId: number) {
    this.isDeletingUser = true;
    this.vmixAdminApiService.deleteUser(aUserId).subscribe((user) => {
      console.log(user);
      if (user) {
        for (let index = 0; index < this.users.length; index++) {
          const usr = this.users[index];
          if(usr.userId == user.userId) {
            this.users.splice(index, 1)
            break;
          }
        }
        this.clearCurUser();
        this.toastService.pushSucess('User deleted');
      }
      this.isDeletingUser = false;
    },
    (err) => {

    });
  }

  private resetUserPassword(aUserId: number) {
    if (!this.disabledResetPassword) {
      this.disabledResetPassword = true;
      this.vmixAdminApiService.resetUserPassword(aUserId).subscribe((res) => {
        this.toastService.pushSucess('Password has been reset !');
        setTimeout(()=> {
          this.disabledResetPassword = false;
        },5000);
      },
      (err) => {
        this.disabledResetPassword = false;
      });
    }
  }

  selectUser(aUserID: number) {
    this.getUser(aUserID);
    this.getUserServerRights(aUserID);
  }

  addNewUser() {
    this.curUser = new User();
    if(this.companies.length) {
      this.curUser.companyId = this.companies[0].companyId
      this.curUser.company = this.companies[0];
    }
  }

  saveCurUser() {
    if(this.curUser) {
      console.log('saveCurUser', this.curUser)
      if(this.curUser.userId == -1) {
        this.addUser(this.curUser);
      } else {
        this.updateUser(this.curUser);
      }
    }
  }

  deleteCurUser() {
    if(this.curUser && this.curUser.userId != -1) {
      let conf = window.confirm('Are you sure you want to delete this user ?');
      if(conf) {
        this.deleteUser(this.curUser.userId);
      }
    } else {
      this.clearCurUser();
    }
  }

  resetCurUserPwd() {
    if(this.curUser && this.curUser.userId != -1 && !this.disabledResetPassword) {
      let conf = window.confirm('Are you sure you want to reset this user password ?');
      if(conf) {
        this.resetUserPassword(this.curUser.userId);
      }
    }
  }

  clearUserServerRights() {
    this.curUserServerRights = [];
  }

  clearServers() {
    this.servers = [];
  }

  private getServers() {
    this.isLoadingServers = true;
    this.clearServers();
    this.vmixAdminApiService.getServers().subscribe((servers) => {
      console.log(servers);
      if (servers) {
        this.servers = servers;
      }
      this.isLoadingServers = false;
    },
    (err) => {

    });
  }

  private getUserServerRights(aUserId: number) {
    this.isLoadingUserServerRights = true;
    this.clearUserServerRights();
    this.vmixAdminApiService.getUserServerRights(aUserId).subscribe((userServerRights) => {
      console.log(userServerRights);
      if (userServerRights) {
        this.curUserServerRights = userServerRights;
      }
      this.isLoadingUserServerRights = false;
    },
    (err) => {

    });
  }

  get filtredUserServerRights(): UserServerRights[] {
    if(this.userRightsFilter.trim().length) {
      return this.curUserServerRights.filter((item)=> 
        item.server.serverName.toUpperCase().includes(this.userRightsFilter.trim().toUpperCase())
      )
    } else {
      return this.curUserServerRights;
    }
  }

  showCompaniesPp() {
    this.curCompany = this.companies[0];
    this.companiesPpShown = true;
  }

  hideCompaniesPp() {
    this.companiesPpShown = false;
  }

  showUsrSrvRightPp() {
    this.usrSrvRightPpShown = true;
  }

  hideUsrSrvRightPp() {
    this.usrSrvRightPpShown = false;
  }

  clearCurUserServerRight() {
    this.curUserServerRight = null;
  }

  changeUsrAlwaysActive() {
    if(this.usrAlwaysActive) {
      this.curUserServerRight.fromDate = null;
      this.curUserServerRight.toDate = null;
    }
  }

  selectUsrSrvRight(aUserServerRights: UserServerRights) {
    this.curUserServerRight = new UserServerRights(Object.assign({}, aUserServerRights));
    this.usrAlwaysActive = this.curUserServerRight.fromDate == null && this.curUserServerRight.toDate == null;
  }

  addNewUserServerRight() {
    this.curUserServerRight = new UserServerRights();
    this.curUserServerRight.userId = this.curUser.userId;
    this.usrAlwaysActive = true;
    this.showUsrSrvRightPp();
  }

  editCurUserServerRight() {
    this.showUsrSrvRightPp();
  }

  private addUserServerRight(aUserServerRight: UserServerRights) {
    this.isSavingUserServerRight = true;
    this.vmixAdminApiService.addUserServerRight(aUserServerRight).subscribe((userServerRight) => {
      console.log(userServerRight);
      if (userServerRight) {
        this.curUserServerRights.unshift(new UserServerRights(Object.assign({}, userServerRight)));  
        this.curUserServerRight = userServerRight;
        this.hideUsrSrvRightPp();
        this.toastService.pushSucess('Right saved !');
      }
      this.isSavingUserServerRight = false;
    },
    (err) => {

    });
  }

  private deleteUserServerRight(aUserServerRightId: number) {
    this.isDeletingUserServerRight = true;
    this.vmixAdminApiService.deleteUserServerRight(aUserServerRightId).subscribe((userServerRight) => {
      console.log(userServerRight);
      if (userServerRight) {
        for (let index = 0; index < this.curUserServerRights.length; index++) {
          const usr = this.curUserServerRights[index];
          if(usr.userServerRightsId == userServerRight.userServerRightsId) {
            this.curUserServerRights.splice(index, 1)
            break;
          }
        }
        this.clearCurUserServerRight();
        this.toastService.pushSucess('Right deleted !');
      }
      this.isDeletingUserServerRight = false;
    },
    (err) => {

    });
  }

  private updateUserServerRight(aUserServerRight: UserServerRights) {
    this.isSavingUserServerRight = true;
    this.vmixAdminApiService.updateUserServerRight(aUserServerRight).subscribe((userServerRight) => {
      console.log(userServerRight);
      if (userServerRight) {
        for (let index = 0; index < this.curUserServerRights.length; index++) {
          const usr = this.curUserServerRights[index];
          if(usr.userServerRightsId == userServerRight.userServerRightsId) {
            this.curUserServerRights.splice(index, 1, new UserServerRights(Object.assign({}, userServerRight)))
            break;
          }
        }
        this.curUserServerRight = userServerRight;
        this.hideUsrSrvRightPp();
        this.toastService.pushSucess('Right saved !');
      }
      this.isSavingUserServerRight = false;
    },
    (err) => {

    });
  }

  saveCurUserServerRight() {
    if(this.curUserServerRight) {
      if(this.curUserServerRight.userServerRightsId == -1) {
        this.addUserServerRight(this.curUserServerRight);
      } else {
        this.updateUserServerRight(this.curUserServerRight);
      }
    }
  }

  deleteCurUserServerRight() {
    let conf = window.confirm('Are you sure you want to delete this right ?');
    if(conf) {
      this.deleteUserServerRight(this.curUserServerRight.userServerRightsId);
    }
  }

  private updateCompany(aCompany: Company) {
    this.isSavingCompany = true;
    this.vmixAdminApiService.updateCompany(aCompany).subscribe((company) => {
      console.log(company);
      if (company) {
        for (let index = 0; index < this.companies.length; index++) {
          const tmpComp = this.companies[index];
          if(tmpComp.companyId == company.companyId){
            this.companies.splice(index, 1, company);
            this.curCompany = company;
            break;
          }
        }
        this.toastService.pushSucess('Company saved');
      }
      this.isSavingCompany = false;
    },
    (err) => {

    });
  }

  private addCompany(aCompany: Company) {
    this.isSavingCompany = true;
    this.vmixAdminApiService.addCompany(aCompany).subscribe((company) => {
      console.log(company);
      if (company) {
        this.companies.unshift(company);
        this.curCompany = company;
        this.toastService.pushSucess('Company saved');
      }
      this.isSavingCompany = false;
    },
    (err) => {

    });
  }

  private deleteCompany(aCompany: Company){
    this.isDeletingCompany = true;
    this.vmixAdminApiService.deleteCompany(aCompany.companyId).subscribe((company) => {
      console.log(company);
      if (company) {
        for (let index = 0; index < this.companies.length; index++) {
          const tmpComp = this.companies[index];
          if(tmpComp.companyId == company.companyId) {
            this.companies.splice(index, 1)
            break;
          }
        }
        this.clearCurCompany();
        this.toastService.pushSucess('Company deleted');
      }
      this.isDeletingCompany = false;
    },
    (err) => {

    });
  }

  selectCompany(aCompany: Company){
    this.curCompany = Object.assign({}, aCompany);
  }

  addNewCompany(){
    let newComp = new Company();
    newComp.companyId = -1;
    newComp.companyName = 'New Company';
    this.curCompany = newComp;
    setTimeout(()=>{
      let input: HTMLInputElement = document.getElementById('companyName') as HTMLInputElement;
      input.focus();
      //input.setSelectionRange(0, input.value.length);
      input.select();
    },50)

  }

  saveCurCompany() {
    if (this.curCompany) {
      if (this.curCompany.companyId == -1) {
        this.addCompany(this.curCompany);
      } else {
        this.updateCompany(this.curCompany);
      }
    }
  }

  deleteCurCompany() {
    if (this.curCompany.companyId == -1){
      this.clearCurCompany();
    } else {
      let conf = window.confirm('Delete this company ?');
      if (conf) {
        this.deleteCompany(this.curCompany);
      }
    }
  }

  /* ----------------------------------------------------------------------
  -- DATES TOOLS
  -------------------------------------------------------------------------*/
  leadingZero(v:number): string {
    if (v<10) {
      return '0'+v;
    }
    return v.toString();
  }

  formatDate(aDate: string) {
    if (aDate) {
      const d1 = new Date(aDate);
      const d2 = new Date(d1.setMinutes(d1.getMinutes()-d1.getTimezoneOffset()));
      const res = d2.getFullYear() + '-' + this.leadingZero(d2.getMonth()+1) + '-' + this.leadingZero(d2.getDate()) + 'T' + this.leadingZero(d2.getHours()) + ':' + this.leadingZero(d2.getMinutes());
      return res;
    } else {
      return null;
    }
  }

  changeDate(aParam: string, object: any, e){
    if (object && object.hasOwnProperty(aParam)) {
      const d1 = new Date(e.target.value);
      const d2 = new Date(d1.setMinutes(d1.getMinutes()+d1.getTimezoneOffset()));
      const res = d2.getFullYear() + '-' + this.leadingZero(d2.getMonth()+1) + '-' + this.leadingZero(d2.getDate()) + 'T' + this.leadingZero(d2.getHours()) + ':' + this.leadingZero(d2.getMinutes());
      object[aParam] = res;
    }
  }

}

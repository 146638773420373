import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Server, ServerShortcuts, ServerTitlePresetResp, ServerTitlePresetTypes, Shortcut, TitlePreset } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class VmixRemoteApiService {
  private ApiUrlRemote = environment.vmixApiUrl + '/remote';
  private ApiUrlPreset = environment.vmixApiUrl + '/presets';

  constructor(private http: HttpClient) { }

  private get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
  }

  public getServers(): Observable<Server[]> {
    console.log('getServers (remote)');
    return this.http.get<Server[]>(this.ApiUrlRemote + '/servers', this.httpOptions);
  }

  public getServer(aServerId: number): Observable<Server> {
    console.log('getServers (remote)');
    return this.http.get<Server>(this.ApiUrlRemote + '/servers/' + aServerId, this.httpOptions);
  }


  public getShortcuts(aServerId: number): Observable<ServerShortcuts[]> {
    console.log('getShortcuts (remote)');
    return this.http.get<ServerShortcuts[]>(this.ApiUrlRemote + '/' + aServerId + '/shortcuts', this.httpOptions);
  }

  public shortcutsAction(aServerId: number, aShortcutId: string): Observable<any> {
    console.log('executeShortcuts (remote)');
    return this.http.get<any>(this.ApiUrlRemote + '/' + aServerId + '/shortcut-action/' + aShortcutId, this.httpOptions);
  }

  public getPresetsTypes(aServerId: number): Observable<ServerTitlePresetTypes[]> {
    console.log('getPresetsTypes (preset)');
    return this.http.get<ServerTitlePresetTypes[]>(this.ApiUrlPreset + '/types/' + aServerId, this.httpOptions);
  }
  
  public getPreset(aPresetTypeId : number): Observable<ServerTitlePresetResp> {
    console.log('getPreset (preset)');
    return this.http.get<ServerTitlePresetResp>(this.ApiUrlPreset + '/' + aPresetTypeId, this.httpOptions);
  }

  public applyPreset(aPreset : TitlePreset): Observable<any> {
    console.log('applyPreset (preset)');
    return this.http.post<any>(this.ApiUrlPreset + '/apply' , aPreset, this.httpOptions);
  }

  public addPreset(aPreset : TitlePreset): Observable<any> {
    console.log('addPreset (preset)');
    return this.http.put<any>(this.ApiUrlPreset , aPreset, this.httpOptions);
  }

  public deletePreset(aPresetId : number): Observable<any> {
    console.log('deletePreset (preset)');
    return this.http.delete<any>(this.ApiUrlPreset + '/' + aPresetId, this.httpOptions);
  }
  
  
}

export class Shortcut {
    id: string;
    title: string;
    description1: string;
    description2: string;
    disabled: boolean;
    hidden: boolean;
    function: string;
    triggerKeys: string;
    expendedKey: string;
    duration: string;
    value: string;
    input: string;
    displayType: string;
    displayValue: string;

    constructor() {

    }
}

export class ServerShortcuts {
    serverId: number;
    serverName: string;
    vmixShortcuts: Shortcut[];

    constructor() {
        this.serverId = -1;
        this.serverName = '';
        this.vmixShortcuts = [];
    }
}
<div class="admin-top">
    <div class="location">
        <div class="current">Users</div>
        <div class="path"><i class="fa fa-home"></i><span class="sep">/</span>Admin<span class="sep">/</span><span class="last">Users</span></div>
    </div>
    <div class="actions">
        <button class="blue" (click)="addNewUser()">Add user</button>
        <button class="green" *ngIf="curUser && !isLoadingUser" (click)="saveCurUser()">Save</button>
        <button class="red" *ngIf="curUser && !isLoadingUser" (click)="deleteCurUser()">Delete</button>
    </div>
</div>

<div class="list-container">

    <div class="list-left-container" *ngIf="users.length && !isLoadingUsers">
        <div class="filter">
            <input type="search" placeholder="Filter..." [(ngModel)]="userFilter">
        </div>
        <div class="list noselect">
            <div *ngIf="curUser && (curUser.userId == -1)" class="company-group">
                <div class="company">Temporary Company</div>
                <div  class="usr-item active">New User</div>
            </div>
            <div *ngFor="let compGrp of usersFiltred" class="company-group">
                <div class="company">{{compGrp.companyName}}</div>
                <div *ngFor="let user of compGrp.users" class="usr-item" (click)="selectUser(user.userId)" [class.active]="curUser && (curUser.userId == user.userId)">{{user.lastName}} {{user.firstName}}</div>
            </div>
        </div>
    </div>
    <div class="list-left-container" *ngIf="!users.length && !isLoadingUsers">
        <div class="empty-msg medium">No user...</div>
    </div>
    <div class="list-left-container" *ngIf="isLoadingUsers">
        <div class="loading medium">Loading...</div>
    </div>


    <div class="list-main-container" *ngIf="curUser && !isLoadingUser">
        <div class="frm">
            <h1>Information</h1>
            <div class="frm-input">
                <label for="firstName">First name</label>
                <input type="text" id="firstName" [(ngModel)]="curUser.firstName" placeholder="Enter a first name">
            </div>
            <div class="frm-input">
                <label for="lastName">Last name</label>
                <input type="text" id="lastName" [(ngModel)]="curUser.lastName" placeholder="Enter a last name">
            </div>
            <div class="frm-input">
                <label for="emailAddress">Email</label>
                <input type="email" id="emailAddress" [(ngModel)]="curUser.emailAddress" placeholder="Enter an email">
            </div>
            <div class="frm-input">
                <label for="pwd">Password <span *ngIf="curUser && (curUser.userId != -1)" class="link act-link" (click)="resetCurUserPwd()">(reset)</span></label>
                <input type="password" id="pwd" value="********" placeholder="Password" readonly>
            </div>
            <div class="frm-input">
                <label for="company">Company <span class="link act-link" (click)="showCompaniesPp()">(Edit)</span></label>
                <select id="company" [(ngModel)]="curUser.companyId">
                    <option *ngFor="let company of companies" [ngValue]="company.companyId">{{company.companyName}}</option>
                </select>
            </div>
            <div class="frm-input">
                <label for="roleVal">Multi-Remote</label>
                <select [(ngModel)]="curUser.allowMultiRemote">
                    <option [ngValue]="false">No</option>
                    <option [ngValue]="true">Yes</option>
                </select>
            </div>
            <div class="frm-input">
                <label for="roleVal">Role</label>
                <select [(ngModel)]="curUser.roleVal">
                    <option value="USER">User</option>
                    <option value="ADMIN">Admin</option>
                </select>
            </div>
        </div>
        
        <div class="frm" *ngIf="curUser && curUser.userId != -1 && curUser.roleVal != 'ADMIN'">
            <h1>
                Rights
                <span class="title-actions">
                    <span class="link red" *ngIf="curUserServerRight" (click)="deleteCurUserServerRight()">Delete</span>
                    <span class="link" *ngIf="curUserServerRight" (click)="editCurUserServerRight()">Edit</span>
                    <span class="link" (click)="addNewUserServerRight()">Add new</span>
                </span>
            </h1>
            <div class="filter-bar">
                <input type="search" [(ngModel)]="userRightsFilter" placeholder="Filter rights...">
            </div>
            <div id="usrSrvRightList" class="ctable noselect">
                <div class="crow head">
                    <div class="ccell">Server name</div>
                    <div class="ccell">From</div>
                    <div class="ccell">To</div>
                </div>
                <div *ngFor="let userServerRight of filtredUserServerRights"  class="crow user-server-right-line" [class.active]="userServerRight.isActive" [class.selected]="curUserServerRight && (userServerRight.userServerRightsId == curUserServerRight.userServerRightsId)" (click)="selectUsrSrvRight(userServerRight)" (dblclick)="editCurUserServerRight()">
                    <div class="ccell">{{userServerRight.server.serverName}}</div>
                    <div class="ccell">{{formatDate(userServerRight.fromDate) | date: 'dd/MM/yy HH:mm'}}</div>
                    <div class="ccell">{{formatDate(userServerRight.toDate) | date: 'dd/MM/yy HH:mm'}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="list-main-container" *ngIf="!curUser && !isLoadingUser">
        <div class="empty-msg">Select a user</div>
    </div>
    <div class="list-main-container" *ngIf="isLoadingUser">
        <div class="loading">Loading...</div>
    </div>
</div>

<div id="usr-srv-right-edit" class="full-popup" *ngIf="curUserServerRight && usrSrvRightPpShown">
    <div class="popup">
        <div class="frm">
            <h1>User Server Right</h1>
            <div class="frm-input fullwidth">
                <label for="serverId">Server</label>
                <select id="serverId" [(ngModel)]="curUserServerRight.serverId">
                    <option *ngFor="let server of servers" [ngValue]="server.serverId">{{server.serverName}}</option>
                </select>
            </div>
            <div class="frm-input fullwidth checkbox">
                <label for="alwaysActive">Is always active</label>
                <input type="checkbox" id="alwaysActive" [(ngModel)]="usrAlwaysActive" (change)="changeUsrAlwaysActive()">
            </div>
            <div class="frm-input" *ngIf="!usrAlwaysActive">
                <label for="fromdate">From</label>
                <input type="datetime-local" id="fromdate" [ngModel]="formatDate(curUserServerRight.fromDate)" (change)="changeDate('fromDate', curUserServerRight, $event)" placeholder="Enter a date">
            </div>
            <div class="frm-input" *ngIf="!usrAlwaysActive">
                <label for="todate">To</label>
                <input type="datetime-local" id="todate" [ngModel]="formatDate(curUserServerRight.toDate)" (change)="changeDate('toDate', curUserServerRight, $event)" placeholder="Enter a date">
            </div>
            <div class="actions">
                <!--<button class="red">Delete</button>-->
                <button class="blue" (click)="saveCurUserServerRight()">Save</button>
                <button (click)="hideUsrSrvRightPp()">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div id="company-edit" class="full-popup" *ngIf="companiesPpShown">
    <div class="popup">
        <div class="frm">
            <h1>Companies</h1>
            <div class="frm-input fullwidth">
                <label for="companyId">Company</label>
                <div class="select-list">
                    <div class="select-item selected" *ngIf="curCompany && curCompany.companyId == -1">{{curCompany.companyName}}</div>
                    <div class="select-item" *ngFor="let company of companies" [class.selected]="curCompany.companyId==company.companyId" (click)="selectCompany(company)">{{company.companyName}}</div>
                </div>
            </div>
            <div class="frm-input fullwidth">
                <label for="companyName">Company Name</label>
                <input *ngIf="curCompany" type="text" id="companyName" [(ngModel)]="curCompany.companyName">
                <input *ngIf="!curCompany" type="text" id="companyName" disabled readonly>
            </div>
            <div class="actions">
                <button class="red" (click)="deleteCurCompany()">Delete</button>
                <button class="blue" (click)="addNewCompany()">Add</button>
                <button class="green" (click)="saveCurCompany()">Save</button>
                <button (click)="hideCompaniesPp()">Close</button>
            </div>
        </div>
    </div>
</div>
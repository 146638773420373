<div class="shortcut-item v1"
    (click)="clickShortcut()" [style.width]="(btWidth+buttonRatio*btWidthRatio)+'px'"
    [style.height]="(btHeight+buttonRatio*btHeightRatio)+'px'" [style.fontSize]="(19+buttonRatio)+'px'"
    [style.backgroundImage]="shortcut.displayType=='Image' && 'url(data:image/jpg;base64,'+ shortcut.displayValue+')'"
    [style.backgroundColor]="shortcut.displayType=='Colour' && shortcut.displayValue"
    [style.color]="shortcut.displayType=='Colour' && getFontColorByBgColor(shortcut.displayValue)"
    [style.borderColor]="shortcut.displayType=='Colour' && adjustBrightness(shortcut.displayValue,-100)">
    <span *ngIf="shortcut.displayType!='Image'" class="title">{{shortcut.title}}</span>
    <span *ngIf="shortcut.displayType=='Default'" class="desc1">{{shortcut.function}}</span>
    <span *ngIf="shortcut.displayType!='Image'" class="desc1">{{shortcut.description1}}</span>
    <span *ngIf="shortcut.displayType!='Image'" class="desc2">{{shortcut.description2}}</span>
    <span class="status" [ngClass]="shortcutStatusClass"></span>
</div>
export class Status {
    shortcutId: string;
    statusLabel: string;
    statusType: string;
    statusValue: string;
    buttonStatusValue: boolean;
    showInList: boolean;

    constructor() {

    }
}

export class ServerStatus {
    serverId: number;
    serverName: string;
    statusList: Status[];

    constructor() {
        this.serverId = -1;
        this.serverName = '';
        this.statusList = [];
    }
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '@app/services/user.service';
import { AuthenticationService } from '@app/services/authentication.service';
import { ToastService } from '@app/services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    hide:boolean;
    loading: boolean;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private toastService: ToastService,
    ) {
        // redirect to home if already logged in
        if (this.userService.currentUserValue) {
            this.router.navigate(['/remote']);
        }
    }

    ngOnInit() {
      this.loading = false;
      this.hide = true;
      //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/remote';
      this.loginForm = this.formBuilder.group({
          email: ['', Validators.required],
          password: ['', Validators.required]
      });
    }

    get f() { return this.loginForm.controls; }

    submit() {
      if (!this.loading) {
        // stop here if form is invalid
        if (this.loginForm.invalid) {
              this.toastService.pushAlert('Please check your credentials');
              return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value,  window.btoa(this.f.password.value))
            .pipe(first())
            .subscribe(
                data => {
                  this.toastService.pushSucess('Welcome ' + this.userService.currentUserValue.firstName + '!');
                  this.returnUrl = this.route.snapshot.queryParams['returnUrl']
                  if(this.returnUrl){
                    this.router.navigateByUrl(this.returnUrl);
                  } else {
                    this.router.navigate(['/remote']);
                  }
                },
                error => {
                  this.loading = false;
                });
        }
    }
}

<div class="main-container">
    <div class="left-bar noselect">
        <div class="nav-container">
            <div routerLink="/admin/server" routerLinkActive="active"><i class="icon fa fa-server"></i> Servers</div>
            <div routerLink="/admin/user" routerLinkActive="active"><i class="icon fa fa-users"></i>Users</div>
        </div>
    </div>
    <div class="center-container">
        <router-outlet></router-outlet>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggedUser, Server } from '@app/models';
import { SocketService } from '@app/services/socket.service';
import { ToastService } from '@app/services/toast.service';
import { UserService } from '@app/services/user.service';
import { VmixRemoteApiService } from '@app/services/vmix-remote-api.service';
import { concat } from 'rxjs';

@Component({
  selector: 'app-server-selector',
  templateUrl: './server-selector.component.html',
  styleUrls: ['./server-selector.component.scss']
})
export class ServerSelectorComponent implements OnInit {
  isLoadingServer: boolean;

  curServers: Server[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private userService: UserService,
    private vmixRemoteApiService: VmixRemoteApiService,
    private socketService: SocketService
  ) { }

  ngOnInit(): void {
    this.isLoadingServer = false;

    this.curServers = [];

    this.getServers();

    if(this.socketService.getStatus() == 3){
      this.socketService.quitGroup(-1);
    }
  }

  clearCurServers() {
    this.curServers = [];
  }

  private getServers() {
    this.isLoadingServer = true;
    this.clearCurServers();
    this.vmixRemoteApiService.getServers().subscribe((servers) => {
      console.log(servers);
      if (servers) {
        if (this.userService.currentUserValue.allowMultiRemote){
          let allServers = new Server();
          allServers.serverName = 'All'
          allServers.serverId = -1;
          this.curServers = servers.concat(allServers);
        } else {
          this.curServers = servers
        }
      }
      this.isLoadingServer = false;
    },
    (err) => {

    });
  }

  selectServer(aServer: Server) {
    this.router.navigate(['/remote/server/'+aServer.serverId]);
  }

}

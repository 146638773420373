import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject, Observable, Observer, Subscriber } from 'rxjs';
import { environment } from '@env/environment';
import { promise } from 'selenium-webdriver';
import { ServerStatus } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public statusChange: EventEmitter<number> = new EventEmitter();
  public onAuth: EventEmitter<any> = new EventEmitter();
  public onJoinGroup: EventEmitter<any> = new EventEmitter();
  public onQuitGroup: EventEmitter<any> = new EventEmitter();
  public onStatus: EventEmitter<ServerStatus> = new EventEmitter();
  public onRefreshShortcuts: EventEmitter<any> = new EventEmitter();
  public onPresetsChanged: EventEmitter<any> = new EventEmitter();
  public onCurrentPresetValue: EventEmitter<any> = new EventEmitter();

  private sockStatus: number;

  private connection = new signalR.HubConnectionBuilder()
    .withUrl(environment.socketUrl)
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  constructor() { 
    this.setStatus(0);

    this.connection.start().then(() => {
      console.log('Connection started');
      this.setStatus(1);
    })
    .catch(err => console.log('Error while starting connection: ' + err))

    this.connection.onclose((e)=>{
      this.setStatus(0);
    })

    this.connection.onreconnecting((e)=>{
      this.setStatus(4);
    })

    this.connection.onreconnected((e)=>{
      this.setStatus(1);
    })

    this.connection.on('Status', (data) => { 
      this.onStatus.emit(data);
    });

    this.connection.on('Auth', (data) => { 
      this.onAuth.emit(data);
    });

    this.connection.on('JoinGroup', (data) => { 
      this.onJoinGroup.emit(data);
    });

    this.connection.on('QuitGroup', (data) => { 
      this.onQuitGroup.emit(data);
    });

    this.connection.on('refresh-shortcuts', (data) => { 
      this.onRefreshShortcuts.emit(data);
    });

    this.connection.on('PresetsChanged', (data) => { 
      this.onPresetsChanged.emit(data);
    });

    this.connection.on('CurrentPresetValue', (data) => { 
      this.onCurrentPresetValue.emit(data);
    });  
  }

  private setStatus(aStatus: number){
    this.sockStatus = aStatus;
    this.statusChange.emit(aStatus);
  }

  public getStatus(): number {
    return this.sockStatus 
  }

  /*
  private sendMessage(aMsg: string) {
    //this.connection.send()
  }
  */

  public auth(aToken: string): Promise<void> {
    return this.connection.send('Auth', aToken).then(()=>{
      this.setStatus(2);
    });
  }

  public joinGrp(aServerId: number): Promise<void> {
    return this.connection.send('JoinGroup', aServerId).then(()=>{
      this.setStatus(3);
    });
  }

  public quitGroup(aServerId: number): Promise<void> {
    return this.connection.send('QuitGroup', aServerId).then(()=>{
      this.setStatus(2);
    });
  }
  
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LoggedUser } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject: BehaviorSubject<LoggedUser>;
  public currentUser: Observable<LoggedUser>;

  constructor() {
    this.currentUserSubject = new BehaviorSubject<LoggedUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoggedUser {
    return this.currentUserSubject.value;
  }

  get isAdm(): boolean {
    return this.currentUserSubject.value.roleVal === 'ADMIN' || this.currentUserSubject.value.roleVal === 'SUPERADMIN';
  }
  get isSa(): boolean {
    return this.currentUserSubject.value.roleVal === 'SUPERADMIN';
  }

  setUser(aUser: LoggedUser) {
    localStorage.setItem('currentUser', JSON.stringify(aUser));
    this.currentUserSubject.next(aUser);
  }

  setUserData(aRatio: number) {
    localStorage.setItem('userData', JSON.stringify(
      {
        ratio: aRatio
      }
    ));
  }

  getUserData() {
    let usrData = localStorage.getItem('userData');
    if (usrData) {
      return JSON.parse(localStorage.getItem('userData'));
    }
    return null;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    window.location.reload();
  }

}

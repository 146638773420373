import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers';
import { AdminComponent } from './components/admin/admin.component';
import { LoginComponent } from './components/login/login.component';
import { RemoteComponent } from './components/remote/remote.component';
import { ServerSelectorComponent } from './components/remote/server-selector/server-selector.component';
import { ServerRemoteComponent } from './components/remote/server-remote/server-remote.component';
import { AdminUserComponent } from './components/admin/admin-user/admin-user.component';
import { AdminServerComponent } from './components/admin/admin-server/admin-server.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    // canActivate: [AuthGuard],
    // data: { mustBeAuth: false }
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    // data: { mustBeAuth: false }
    children: [
      {
        path: '',
        redirectTo: 'user',
        pathMatch: 'full'
      },
      {
        path: 'user',
        component: AdminUserComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      },
      {
        path: 'server',
        component: AdminServerComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      }
    ]
  },
  {
    path: 'remote',
    component: RemoteComponent,
    canActivate: [AuthGuard],
    // data: { mustBeAuth: false }
    children: [
      {
        path: '',
        redirectTo: 'selector',
        pathMatch: 'full'
      },
      {
        path: 'selector',
        component: ServerSelectorComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      },
      {
        path: 'server/:serverId',
        component: ServerRemoteComponent,
        canActivate: [AuthGuard],
        // data: { mustBeAuth: true }
      }
    ]
  }, {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

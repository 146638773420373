import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { LoggedUser } from '@app/models';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  private ApiUrl = environment.vmixApiUrl + '/auth';

  constructor(
    private http: HttpClient,
    private userService: UserService
    ) { }

  private get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
  }

  login(aUsername: string, aPassword: string): Observable<any> {
    console.log('login');
    const data = { login: aUsername, password: aPassword }
    return this.http.post<any>(this.ApiUrl + '/login', data, this.httpOptions)
    .pipe(map(response => {
      // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
      const loggedUser = new LoggedUser();
      loggedUser.emailAddress = response.user.emailAddress;
      loggedUser.firstName = response.user.firstName;
      loggedUser.lastName = response.user.lastName;
      loggedUser.defaultPassword = response.user.defaultPassword;
      loggedUser.roleVal = response.user.roleVal;
      loggedUser.allowMultiRemote = response.user.allowMultiRemote;
      loggedUser.authdata = response.session_token;
      this.userService.setUser(loggedUser);
      return loggedUser;
    }));
  }

  changePassword(aPassword: string): Observable<any> {
    console.log('changePassword');
    const usr = {
      pwd: aPassword
    };
    return this.http.patch<any>(this.ApiUrl + '/users/password', usr, this.httpOptions);
  }
}

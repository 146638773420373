import { Server } from "./server";

export class UserServerRights {
    userServerRightsId: number;
    userId: number;
    serverId: number;
    fromDate: string;
    toDate: string;
    server: Server;

    constructor(o?:UserServerRights) {
        if (o) {
            this.userServerRightsId = o.userServerRightsId;
            this.userId = o.userId;
            this.serverId = o.serverId;
            this.fromDate = o.fromDate;
            this.toDate = o.toDate;
            this.server = o.server;
        } else {
            this.userServerRightsId = -1;
            this.userId = -1;
            this.serverId = -1;
            this.fromDate = '';
            this.toDate = '';
            this.server = null;
        }
    }

    get isActive(): boolean {
        let fromDate = new Date(this.fromDate +'Z');
        let toDate = new Date(this.toDate +'Z');
        let now = new Date();
        return  (fromDate < now && toDate > now) || 
                (this.fromDate == null && toDate > now) ||
                (fromDate < now && this.toDate == null) ||
                (this.fromDate == null && this.toDate == null);
    }
}
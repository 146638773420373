import { Company } from "./company";
import { UserServerRights } from "./user-server-right";

export class LoggedUser {
    id: number;
    emailAddress: string;
    firstName: string;
    lastName: string;
    defaultPassword: boolean;
    roleVal: string;
    companyId: number;
    company: Company;
    allowMultiRemote: boolean;
    authdata?: string;

    constructor() {

    }
}

export class User {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    roleVal: string;
    companyId: number;
    company: Company;
    allowMultiRemote: boolean;
    //pwd: string;
    //salt: string;
    //defaultPassword: boolean;
    userServerRights: UserServerRights[];

    constructor() {
        this.userId = -1;
        this.firstName = '';
        this.lastName = '';
        this.emailAddress = '';
        this.roleVal = 'USER';
        this.companyId = -1;
        this.company = null;
        this.allowMultiRemote = false;
        this.userServerRights = [];
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Company, Server, User, UserServerRights } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class VmixAdminApiService {
  private ApiUrl = environment.vmixApiUrl + '/admin';

  constructor(private http: HttpClient) { }

  private get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
  }

  // ------------------------------------------------------------------------------
  // SERVERS
  // ------------------------------------------------------------------------------

  public getServers(): Observable<Server[]> {
    console.log('getServers (admin)');
    return this.http.get<Server[]>(this.ApiUrl + '/servers', this.httpOptions);
  }

  public getServer(aServerId: number): Observable<Server> {
    console.log('getServer (admin): serverId', aServerId);
    return this.http.get<Server>(this.ApiUrl + '/servers/' + aServerId, this.httpOptions);
  }

  public addServer(aServer: Server): Observable<Server> {
    console.log('addServer (admin)');
    return this.http.put<Server>(this.ApiUrl + '/servers', aServer, this.httpOptions);
  }

  public updateServer(aServer: Server): Observable<Server> {
    console.log('updateServer (admin)');
    return this.http.post<Server>(this.ApiUrl + '/servers', aServer, this.httpOptions);
  }
  
  public deleteServer(aServerId: number): Observable<any> {
    console.log('deleteServer (admin): serverId', aServerId);
    return this.http.delete<any>(this.ApiUrl + '/servers/' + aServerId, this.httpOptions);
  }

  public refreshShortcuts(aServerId: number): Observable<any> {
    console.log('updateUser (admin)');
    return this.http.post<any>(this.ApiUrl + '/servers/' + aServerId + '/refresh-shortcuts', null, this.httpOptions);
  }
  
  // ------------------------------------------------------------------------------
  // USERS
  // ------------------------------------------------------------------------------

  public getUsers(): Observable<User[]> {
    console.log('getUsers (admin)');
    return this.http.get<User[]>(this.ApiUrl + '/users', this.httpOptions);
  }

  public getUser(aUserId: number): Observable<User> {
    console.log('getUser (admin): userId', aUserId);
    return this.http.get<User>(this.ApiUrl + '/users/' + aUserId, this.httpOptions);
  }

  public addUser(aUser: User): Observable<User> {
    console.log('addUser (admin)');
    return this.http.put<User>(this.ApiUrl + '/users', aUser, this.httpOptions);
  }

  public updateUser(aUser: User): Observable<User> {
    console.log('updateUser (admin)');
    return this.http.post<User>(this.ApiUrl + '/users', aUser, this.httpOptions);
  }
  
  public deleteUser(aUserId: number): Observable<any> {
    console.log('deleteUser (admin): userId', aUserId);
    return this.http.delete<any>(this.ApiUrl + '/users/' + aUserId, this.httpOptions);
  }

  public resetUserPassword(aUserId: number): Observable<any> {
    console.log('resetUserPassword (admin)', aUserId);
    const usr = {
      userId: aUserId
    };
    return this.http.post<any>(this.ApiUrl + '/users/password', usr, this.httpOptions);
  }


  // ------------------------------------------------------------------------------
  // COMPANIES
  // ------------------------------------------------------------------------------

  public getCompanies(): Observable<Company[]> {
    console.log('getCompanies');
    return this.http.get<Company[]>(this.ApiUrl + '/companies', this.httpOptions);
  }

  public deleteCompany(aCompanyId: number): Observable<any> {
    console.log('deleteCompany (admin): companyId', aCompanyId);
    return this.http.delete<any>(this.ApiUrl + '/companies/' + aCompanyId, this.httpOptions);
  }

  public addCompany(aCompany: Company): Observable<Company> {
    console.log('addCompany (admin)');
    return this.http.put<Company>(this.ApiUrl + '/companies', aCompany, this.httpOptions);
  }

  public updateCompany(aCompany: Company): Observable<Company> {
    console.log('updateCompany (admin)');
    return this.http.post<Company>(this.ApiUrl + '/companies', aCompany, this.httpOptions);
  }
  // ------------------------------------------------------------------------------
  // USERS SERVER RIGHTS
  // ------------------------------------------------------------------------------

  public getUserServerRights(aUserId: number): Observable<UserServerRights[]> {
    console.log('getUserServerRights (admin): userId', aUserId);
    return this.http.get<UserServerRights[]>(this.ApiUrl + '/users/' + aUserId + '/servers', this.httpOptions)
    .pipe(map((usrs) => {
      return usrs.map((e) => {
        return new UserServerRights(e);
      });
    }));
  }

  public getUserServerRight(aUserServerRightId: number): Observable<UserServerRights> {
    console.log('getUserServerRight (admin): userServerRightId', aUserServerRightId);
    return this.http.get<UserServerRights>(this.ApiUrl + '/users/servers/' + aUserServerRightId, this.httpOptions)
    .pipe(map((e) => {
      return new UserServerRights(e);
    }));
  }

  public addUserServerRight(aUserServerRight: UserServerRights): Observable<UserServerRights> {
    console.log('addUserServerRight (admin)');
    return this.http.put<UserServerRights>(this.ApiUrl + '/users/servers', aUserServerRight, this.httpOptions)
    .pipe(map((e) => {
      return new UserServerRights(e);
    }));
  }

  public updateUserServerRight(aUserServerRight: UserServerRights): Observable<UserServerRights> {
    console.log('updateUserServerRight (admin)');
    return this.http.post<UserServerRights>(this.ApiUrl + '/users/servers', aUserServerRight, this.httpOptions)
    .pipe(map((e) => {
      return new UserServerRights(e);
    }));
  }
  
  public deleteUserServerRight(aUserServerRightId: number): Observable<any> {
    console.log('deleteUserServerRight (admin): userServerRightId', aUserServerRightId);
    return this.http.delete<any>(this.ApiUrl + '/users/servers/' + aUserServerRightId, this.httpOptions);
  }

}

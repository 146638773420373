<div class="admin-top">
    <div class="location">
        <div class="current">Servers</div>
        <div class="path"><i class="fa fa-home"></i><span class="sep">/</span>Admin<span class="sep">/</span><span class="last">Servers</span></div>
    </div>
    <div class="actions">
        <button class="blue" (click)="addNewServer()">Add server</button>
        <button class="green" *ngIf="curServer && !isLoadingServer" (click)="saveCurServer()">Save</button>
        <button class="red" *ngIf="curServer && !isLoadingServer" (click)="deleteCurServer()">Delete</button>
    </div>
</div>

<div class="list-container">
    <div class="list-left-container" *ngIf="servers.length && !isLoadingServers">
        <div class="filter">
            <input type="search" placeholder="Filter..." [(ngModel)]="srvFilterStr">
        </div>
        <div class="list noselect">
            <div *ngFor="let server of serversFiltred" class="srv-item" (click)="selectServer(server.serverId)" [class.active]="curServer && (curServer.serverId == server.serverId)">{{server.serverName}}</div>
            <div *ngIf="curServer && (curServer.serverId == -1)" class="srv-item active">New Server</div> 
        </div>
    </div>
    <div class="list-left-container" *ngIf="!servers.length && !isLoadingServers">
        <div class="empty-msg medium">No server...</div>
    </div>
    <div class="list-left-container" *ngIf="isLoadingServers">
        <div class="loading medium">Loading...</div>
    </div>

    <div class="list-main-container" *ngIf="curServer && !isLoadingServer">
        <div class="frm">
            <h1>Information</h1>
            <div class="frm-input fullwidth">
                <label for="serverName">Server Name</label>
                <input type="text" id="serverName" [(ngModel)]="curServer.serverName" placeholder="Enter a name">
            </div>
            <div class="frm-input">
                <label for="serverAddress">IP Adress</label>
                <input type="text" id="serverAddress" [(ngModel)]="curServer.serverAddress" placeholder="ex. 127.0.0.1">
            </div>
            <div class="frm-input">
                <label for="serverPort">Port</label>
                <input type="number" id="serverPort" [(ngModel)]="curServer.serverPort" placeholder="enter a port">
            </div>
            <div class="frm-input">
                <label for="sftpUser">FTP User</label>
                <form><input type="text" id="sftpUser" name="sftpUser" [(ngModel)]="curServer.sftpUser" placeholder="enter a username" autocomplete="off"></form>
            </div>
            <div class="frm-input">
                <label for="sftpPassword">sFTP Password</label>
                <form><input type="password" id="sftpPassword" name="sftpPassword" [(ngModel)]="curServer.sftpPassword" placeholder="enter a password" autocomplete="off"></form>
            </div>
            <div class="frm-input checkbox">
                <label for="activeFlag">Active</label>
                <input type="checkbox" id="activeFlag" [(ngModel)]="curServer.activeFlag">
            </div>
            <div class="actions" *ngIf="curServer && (curServer.serverId != -1)">
                <button class="blue" (click)="refreshRemoteCurServer()"><i class="fa fa-sync-alt"></i> {{isRefreshingServer? 'Refreshing...': 'Refresh Remote'}}</button>
            </div>
            
        </div>
    </div>
    <div class="list-main-container" *ngIf="!curServer && !isLoadingServer">
        <div class="empty-msg">Select a server</div>
    </div>
    <div class="list-main-container" *ngIf="isLoadingServer">
        <div class="loading">Loading...</div>
    </div>

</div>
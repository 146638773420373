import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './components/admin/admin.component';
import { RemoteComponent } from './components/remote/remote.component';
import { BasicAuthInterceptor } from './helpers/basic-auth.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { ServerSelectorComponent } from './components/remote/server-selector/server-selector.component';
import { ServerRemoteComponent } from './components/remote/server-remote/server-remote.component';
import { AdminServerComponent } from './components/admin/admin-server/admin-server.component';
import { AdminUserComponent } from './components/admin/admin-user/admin-user.component';
import { RemoteButtonComponent } from './components/shared/remote-button/remote-button.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    RemoteComponent,
    ServerSelectorComponent,
    ServerRemoteComponent,
    AdminServerComponent,
    AdminUserComponent,
    RemoteButtonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Shortcut } from '@app/models';

@Component({
  selector: 'app-remote-button',
  templateUrl: './remote-button.component.html',
  styleUrls: ['./remote-button.component.scss']
})
export class RemoteButtonComponent implements OnInit {

  @Input() serverId: number;  
  @Input() shortcut: Shortcut; 
  @Input() buttonRatio: number;  
  @Input() shortcutStatusClass: string;
  
  @Output() onclick: EventEmitter<any> = new EventEmitter();

  isLoadingShortcutAct: boolean;

  btHeight: number;
  btWidth: number;
  btHeightRatio: number;
  btWidthRatio: number;

  constructor() {
    this.serverId = -1;
    this.shortcut = null;
    this.buttonRatio = 1;
    this.isLoadingShortcutAct = false;

    this.btHeight = 100; // v1: 100 / v2: 150
    this.btWidth = 170; // v1: 170 / v2: 150
    this.btHeightRatio = 11.76; // v1: 20 / v2: 11.76;
    this.btWidthRatio = 20; // v1: 20 / v2: 20
  }

  ngOnInit(): void {
  }

  clickShortcut() {
    if(this.serverId > -1 && this.shortcut) {
      this.onclick.emit({serverId:this.serverId, shortcutId: this.shortcut.id});
    }
  }

  getFontColorByBgColor(bgColor: string): string {
    if (!bgColor) {
      return '#000000';
    }
  
    const red = parseInt(bgColor.substr(1, 2), 16);
    const green = parseInt(bgColor.substr(3, 2), 16);
    const blue = parseInt(bgColor.substr(5, 2), 16);
  
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
  
    if (brightness > 127) {
      return '#000000';
    } else {
      return '#ffffff';
    }
  }

  adjustBrightness(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    var R = parseInt(col.substring(0,2),16);
    var G = parseInt(col.substring(2,4),16);
    var B = parseInt(col.substring(4,6),16);

    // to make the colour less bright than the input
    // change the following three "+" symbols to "-"
    R = R + amt;
    G = G + amt;
    B = B + amt;

    if (R > 255) R = 255;
    else if (R < 0) R = 0;

    if (G > 255) G = 255;
    else if (G < 0) G = 0;

    if (B > 255) B = 255;
    else if (B < 0) B = 0;

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return (usePound?"#":"") + RR + GG + BB;
  }
}

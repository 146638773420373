export class Server {
    serverId: number;
    serverName: string;
    serverAddress: string;
    serverPort: number;
    sftpUser: string;
    sftpPassword: string;
    activeFlag: boolean;

    constructor() {
        this.serverId = -1;
        this.serverName = '';
        this.serverAddress = '';
        this.serverPort = 0;
        this.sftpUser = '';
        this.sftpPassword = '';
        this.activeFlag = true;
    }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '@app/services/user.service';
import { ToastService } from '@app/services/toast.service';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private toastService: ToastService,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            switch (err.status) {
                case 401:
                    // auto logout if 401 response returned from api
                    this.toastService.pushAlert("Forbidden action");
                    this.router.navigate(['/remote/']);
                    break;
                case 403:
                    // auto logout if 403 response returned from api
                    this.userService.logout();
                    //window.location.reload();
                    break;
                case 204:
                  this.toastService.pushAlert('No data for this request');
                  break;

                case 400:
                case 500:
                    if (err.error) {
                        for (const e of err.error) {
                            console.error(e.errorCode, e.errorMsg);
                            this.toastService.pushAlert(e.errorMsg);
                        }
                    } else {
                        console.error(err);
                    }
                    break;
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}

<div id="top-bar" *ngIf="isLogged">
    <div id="menu-icon" (click)="showMenu()">
        <i class="fa fa-bars"></i>
    </div>
    <div class="app-name">Voiceover Remote</div>
    <div class="identity">{{user.emailAddress}} <span class="link logout" (click)="logout()">(logout)</span></div>
</div>
<div id="menu-blur-bg" *ngIf="isLogged" [class.visible]="menuIsShown" (click)="hideMenu()"></div>
<div id="main-menu" *ngIf="isLogged" [class.visible]="menuIsShown">
    <div class="back" (click)="hideMenu()"><i class="fa fa-arrow-left"></i></div>
    <div routerLink="/admin" routerLinkActive="active" (click)="hideMenu()" *ngIf="user.roleVal=='ADMIN'">Admin</div>
    <div routerLink="/router" routerLinkActive="active" (click)="hideMenu()">Remote</div>
    <div (click)="logout()">Logout</div>  
</div>

<div id="main-container">
    <router-outlet></router-outlet>
</div>

<div id="rst-pwd-pp" class="full-popup" *ngIf="user && user.defaultPassword">
    <div class="popup">
      
            
        <form class="frm" [formGroup]="changePwdForm">
            <h1>Set your password</h1>

            
            <p class="text font-red text-centred">
                {{user.firstName}}, this is temporary password.<br/>
                Please set your own password.
            </p>

            <div class="frm-input fullwidth">
                <label for="todate">New password</label>
                <input type="password" id="password" formControlName="password" placeholder="Enter a password">
            </div>

            <div class="frm-input fullwidth">
                <label for="todate">Confirm new password</label>
                <input type="password" id="password" formControlName="confirmPassword" placeholder="Enter a password">
            </div>

            <div class="actions">
                <button class="blue" (click)="sumbitChangePassword()">Validate</button>
            </div>
            
        </form>
            
        
    </div>
</div>

<div class="toasts">
    <div class="toast" *ngFor="let toast of toasts; let i = index" [class]="[toast.status, toast.type]" [style.top]="(15 +(i*70))-50+'px'">{{toast.text}}</div>
</div>
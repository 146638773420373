export class TitlePresetTypes {
    titlePresetTypeId: number;
    title: string;

    constructor() {

    }
}

export class ServerTitlePresetTypes {
    serverId: number;
    serverName: string;
    titlePresetTypes: TitlePresetTypes[];

    constructor() {
        this.serverId = -1;
        this.serverName = '';
        this.titlePresetTypes = [];
    }
}

export class TitlePresetDetail {
    titlePresetDetailId: number;
    titlePresetId: number;
    name: string;
    value: string;

    constructor() {
        this.titlePresetDetailId = -1
        this.titlePresetId = -1;
        this.name = '';
        this.value = '';
    }
}

export class TitlePreset {
    titlePresetId: number;
    titlePresetTypeId: number;
    titlePresetDetails: TitlePresetDetail[]

    constructor() {
        this.titlePresetId = -1;
        this.titlePresetTypeId = -1;
        this.titlePresetDetails = [];
    }
}

export class ServerTitlePresetResp {
    serverId: number;
    serverName: string;
    currentValues: TitlePreset;
    savedPresets: TitlePreset[];

    constructor() {
        this.serverId = -1;
        this.serverName = '';
        this.currentValues = null;
        this.savedPresets = [];
    }
}
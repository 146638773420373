import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Server, ServerShortcuts, ServerStatus, ServerTitlePresetResp, ServerTitlePresetTypes, Shortcut, Status, TitlePreset, TitlePresetDetail } from '@app/models';
import { SocketService } from '@app/services/socket.service';
import { ToastService } from '@app/services/toast.service';
import { UserService } from '@app/services/user.service';
import { VmixRemoteApiService } from '@app/services/vmix-remote-api.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-server-remote',
  templateUrl: './server-remote.component.html',
  styleUrls: ['./server-remote.component.scss']
})
export class ServerRemoteComponent implements OnInit, OnDestroy {
  serverId: number;
  isLoadingShortcuts: boolean;
  isLoadingPresetsTypes: boolean;
  isLoadingPreset: boolean;

  shortcutsLoaded: boolean;

  statusBarShown: boolean;
  socketState: number;
  buttonRatio: number;

  curTool: string;
  
  isLoadingShortcutAct: boolean;

  curServersStatus: ServerStatus[];
  curServersShortcuts: ServerShortcuts[];
  curServersPresetsTypes: ServerTitlePresetTypes[];
  curSrvPresetTypeId: number[];
  curServersPresets: ServerTitlePresetResp[];
  editCurSrvPresetLive: TitlePreset[];

  socketStatusDataSub: Subscription;
  socketAuthDataSub: Subscription;
  socketJoinGrpDataSub: Subscription;
  socketQuitGrpDataSub: Subscription;
  socketRefreshShortcutsDataSub: Subscription;
  socketPresetsChanged: Subscription;
  socketCurrentPresetValue: Subscription;

  socketStateLabel = {
    0: 'Disconnected',
    1: 'Identification...',
    2: 'Identified',
    3: 'Connected',
    4: 'Reconnecting...'
  };

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private vmixRemoteApiService: VmixRemoteApiService,
    private socketService: SocketService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      // tslint:disable-next-line: radix
      if(params.get('serverId')) {
        this.serverId = parseInt(params.get('serverId'));
      }
    });

    this.shortcutsLoaded = false;

    this.isLoadingShortcuts = false;
    this.isLoadingPresetsTypes = false;
    this.isLoadingPreset = false;

    this.statusBarShown = false;
    this.socketState = this.socketService.getStatus();
    this.buttonRatio = this.userService.getUserData() ? this.userService.getUserData().ratio : 0;

    this.curTool = 'titles';

    this.isLoadingShortcutAct = false;

    this.editCurSrvPresetLive = [];
    this.curServersPresets = [];
    this.curSrvPresetTypeId = [];
    this.clearCurServersStatus();
    this.clearCurServersShortcuts()
    this.clearCurPresetsTypes();
    
    this.socketService.statusChange.subscribe((aStatus) => {
      if (this.socketState > 0 && (aStatus == 0 || aStatus == 4)) {
        this.toastService.pushAlert('Disconnected from server !');
      } else if (this.socketState == 4 && aStatus == 1) {
        this.toastService.pushSucess('Reconnected to the server !');
      }

      this.socketState = aStatus

      if(aStatus == 1) {
        this.socketService.auth(this.userService.currentUserValue.authdata).then(()=>{
          this.socketService.joinGrp(this.serverId).then(()=>{
            if(!this.shortcutsLoaded){
              this.getSrvShortcuts();
              this.getPresetsTypes();
            }
          })
          .catch((e)=>{
            console.error(e)
          });
        })
        .catch((e)=>{
          console.error(e)
        });
      }
    });

    if(this.socketState == 1) {
      this.socketService.auth(this.userService.currentUserValue.authdata).then(()=>{
        this.socketService.joinGrp(this.serverId).then(()=>{
          if(!this.shortcutsLoaded){
            this.getSrvShortcuts();
            this.getPresetsTypes();
          }
        })
        .catch((e)=>{
          console.error(e)
        });
      })
      .catch((e)=>{
        console.error(e)
      });
    }else if(this.socketState == 2) {
      this.socketService.joinGrp(this.serverId).then(()=>{
        if(!this.shortcutsLoaded){
          this.getSrvShortcuts();
          this.getPresetsTypes();
        }
      })
      .catch((e)=>{
        console.error(e)
      });
    }

    this.socketRefreshShortcutsDataSub = this.socketService.onRefreshShortcuts.subscribe(data=> {
      if(data && data.serverId){
        this.refreshSrvShortcuts(data.serverId)
      }
    });

    this.socketStatusDataSub = this.socketService.onStatus.subscribe(data=> {
      if(data && data.serverId){
        const index = this.curServersStatus.findIndex(tmpSrvStatus => tmpSrvStatus.serverId === data.serverId);
        if (index !== -1) {
          this.curServersStatus.splice(index, 1, data);
        } else {
          this.curServersStatus.push(data);
        }
      }
    });

    this.socketJoinGrpDataSub = this.socketService.onJoinGroup.subscribe((data) => {
      if (data && data.code == '400'){
        this.userService.logout();
      }
    });

    this.socketAuthDataSub = this.socketService.onAuth.subscribe(data=> {
      if (data && data.code == '401'){
        this.userService.logout();
      }
    });

    this.socketQuitGrpDataSub = this.socketService.onQuitGroup.subscribe(data=> {

    });

    this.socketPresetsChanged = this.socketService.onPresetsChanged.subscribe(data=> {
      if (this.curSrvPresetTypeId[data.serverId] == data.titlePresetTypeId) {
        this.getSavedPreset(data.titlePresetTypeId);
      }
    });

    this.socketCurrentPresetValue = this.socketService.onCurrentPresetValue.subscribe(data=> {
      if (this.curSrvPresetTypeId[data.serverId] == data.titlePresetTypeId) {
        this.curServersPresets[data.serverId].currentValues = data.currentValues;
        this.editCurSrvPresetLive[data.serverId] = this.deepCopy(data.currentValues) as TitlePreset
      }
    });
    
  }

  ngOnDestroy() {
    this.shortcutsLoaded = false;
    this.clearSubscriptions();
  }

  private clearSubscriptions(){
    this.socketStatusDataSub.unsubscribe();
    this.socketAuthDataSub.unsubscribe();
    this.socketJoinGrpDataSub.unsubscribe();
    this.socketQuitGrpDataSub.unsubscribe();
    this.socketRefreshShortcutsDataSub.unsubscribe();
    this.socketPresetsChanged.unsubscribe();
    this.socketCurrentPresetValue.unsubscribe();
  }
  clearCurServersStatus() {
    this.curServersStatus = [];
  }
  clearCurServersShortcuts() {
    this.curServersShortcuts = [];
  }

  get serversShortcutsActive(): ServerShortcuts[] {
    return this.curServersShortcuts.filter((s) => {
      return s.vmixShortcuts.length
    })
  }

  private getSrvShortcuts(){
    this.isLoadingShortcuts = true;
    this.clearCurServersShortcuts();
    this.vmixRemoteApiService.getShortcuts(this.serverId).subscribe((srvsShortcuts) => {
      if (srvsShortcuts) {
        this.curServersShortcuts = srvsShortcuts;
        this.shortcutsLoaded = this.curServersShortcuts.length > 0;
      }
      this.isLoadingShortcuts = false;
    },
    (err) => {

    });
  }

  private refreshSrvShortcuts(aServerId: number){
    this.isLoadingShortcuts = true;
    this.clearCurServersShortcuts();
    this.vmixRemoteApiService.getShortcuts(aServerId).subscribe((srvsShortcuts) => {
      if (srvsShortcuts) {
        for (let i = 0; i < srvsShortcuts.length; i++) {
          const newSrvShortcuts = srvsShortcuts[i];
          for (let j = 0; j < this.curServersShortcuts.length; j++) {
            const tmpSrvShortcuts = this.curServersShortcuts[j];
            if(tmpSrvShortcuts.serverId == newSrvShortcuts.serverId){
              this.curServersShortcuts.splice(j, 1, newSrvShortcuts);
              break;
            }
          }
        }
      }
      this.isLoadingShortcuts = false;
    },
    (err) => {

    });
  }

  clickShortcut(aData:{serverId: number, shortcutId: string}) {
    if(aData.serverId > -1 && aData.shortcutId) {
      this.isLoadingShortcutAct = true;
      this.vmixRemoteApiService.shortcutsAction(aData.serverId, aData.shortcutId).subscribe((resp) => {
        if(resp==200) {
          //this.toastService.pushSucess('Action success !');
        } else {
          this.toastService.pushAlert('Action failed !');
        }
        this.isLoadingShortcutAct = false;
      },
      (err) => {
        //this.isLoadingShortcutAct = false;
        console.error(err);
      });
    }
  }

  getShortStatusClass(aServerId: number, aShortcutId: string): string {
    const serverStatus = this.curServersStatus.find(s => s.serverId === aServerId);
  
    if (serverStatus) {
      const status = serverStatus.statusList.find(s => s.shortcutId === aShortcutId);
  
      if (status && status.buttonStatusValue !== null) {
        return status.buttonStatusValue ? 'green' : 'red';
      }
    }
  
    return null;
  }
  

  getStatusPercent(aServerId: number): Status[] {
    for (const serverStatus of this.curServersStatus) {
      if(serverStatus.serverId == aServerId) {
        return serverStatus.statusList.filter((o)=>{
          return o.statusType == 'percentage' && o.showInList
        });
      }
    }
    return [];
  }

  getStatusString(aServerId: number): Status[] {
    for (const serverStatus of this.curServersStatus) {
      if (serverStatus.serverId == aServerId) {
        return serverStatus.statusList.filter((o)=>{
          return o.statusType == 'string' && o.showInList
        });
      }
    }
    return [];
  }

  getStatusStringClass(aStatusValue: string) {
    const greenVal = ['ON', 'UNMUTED', 'ACTIVE'];
    const redVal = ['OFF', 'MUTED', 'INACTIVE'];
    let result = '';
    if (greenVal.indexOf(aStatusValue.toUpperCase())>-1) {
      result = 'green';
    } else if(redVal.indexOf(aStatusValue.toUpperCase())>-1) {
      result = 'red';
    }
    return result;
  }

  backToServerSelection() {
    this.router.navigate(['/remote/']);
  }

  selectTool(aToolName: string) {
    this.curTool = aToolName;
  }

  setBtRatio(aValue: number) {
    this.buttonRatio = aValue; 
    this.userService.setUserData(this.buttonRatio);
  }

  increaseBtRatio() {
    this.buttonRatio++; 
    this.userService.setUserData(this.buttonRatio);
  }

  decreaseBtRatio() {
    this.buttonRatio--; 
    this.userService.setUserData(this.buttonRatio);
  }

  toggleToolBar(){
    this.statusBarShown = !this.statusBarShown;
  }


  /*-----------------------------
  /*-- PRESETS ------------------
  /*-----------------------------*/

  clearCurPresetsTypes() {
    this.curServersPresetsTypes = [];
  }

  private getPresetsTypes() {
    this.isLoadingPresetsTypes = true;
    this.clearCurPresetsTypes();
    this.vmixRemoteApiService.getPresetsTypes(this.serverId).subscribe((srvsPresetsTypes ) => {
      if (srvsPresetsTypes) {
        this.curServersPresetsTypes = srvsPresetsTypes;
        for (const srvPresetsTypes of this.curServersPresetsTypes) {
          this.selectPresetType(srvPresetsTypes.serverId, srvPresetsTypes.titlePresetTypes[0].titlePresetTypeId);
        }
      }
      this.isLoadingPresetsTypes = false;
    },
    (err) => {
      this.isLoadingPresetsTypes = false;
    });
  }

  private getPreset(aPresetTypeId : number) {
    this.isLoadingPreset = true;
    //this.clearCurPresets();
    this.vmixRemoteApiService.getPreset(aPresetTypeId).subscribe((preset) => {
      console.log(preset);
      if (preset) {
        this.curServersPresets[preset.serverId] = preset;
        this.editCurSrvPresetLive[preset.serverId] = this.deepCopy(preset.currentValues) as TitlePreset
        /*
        for (const tmpPreset of this.editCurSrvPresetLive[preset.serverId].titlePresetDetails) {
          tmpPreset.value = '';
        }
        */
      }
      this.isLoadingPreset = false;
    },
    (err) => {
      this.isLoadingPreset = false;
    });
  }

  private getSavedPreset(aPresetTypeId : number) {
    this.isLoadingPreset = true;
    //this.clearCurPresets();
    this.vmixRemoteApiService.getPreset(aPresetTypeId).subscribe((preset) => {
      console.log(preset);
      if (preset) {
        this.curServersPresets[preset.serverId].savedPresets = preset.savedPresets;
      }
      this.isLoadingPreset = false;
    },
    (err) => {
      this.isLoadingPreset = false;
    });
  }

  private applyPreset(aPreset: TitlePreset) {
    this.isLoadingPreset = true;
    this.vmixRemoteApiService.applyPreset(aPreset).subscribe((resp) => {
      console.log(resp);
      if (resp) {
        this.toastService.pushSucess('Titles updated');
      }
      this.isLoadingPreset = false;
    },
    (err) => {
      this.isLoadingPreset = false;
    });
  }

  private addPreset(aPreset: TitlePreset) {
    //this.isLoadingPreset = true;
    this.vmixRemoteApiService.addPreset(aPreset).subscribe((resp) => {
      console.log(resp);
      if (resp) {
        this.toastService.pushSucess('Preset saved');
      }
      //this.isLoadingPreset = false;
    },
    (err) => {
      //this.isLoadingPreset = false;
    });
  }

  private deletePreset(aPresetId: number) {
    //this.isDeletingPreset = true;
    this.vmixRemoteApiService.deletePreset(aPresetId).subscribe((resp) => {
      console.log(resp);
      if (resp) {
        this.toastService.pushSucess('Preset deleted');
      }
      //this.isDeletingPreset = false;
    },
    (err) => {
      //this.isDeletingPreset = false;
    });
  }

  applyPresetLive(aServerId: number) {
    let preset = this.editCurSrvPresetLive[aServerId];
    let hasEmpty = false;
    for (const presetDet of preset.titlePresetDetails) {
      if(!presetDet.value.trim().length) {
        hasEmpty = true;
        break;
      }
    }
    if(!hasEmpty) {
      if(preset){
        this.applyPreset(preset);
      }
    } else {
      this.toastService.pushAlert('Can\'t send empty data');
    }
  }

  selectPresetType(aServerId: number, aPresetTypeId: number) {
    console.log('selectPresetType', aPresetTypeId)
    this.curSrvPresetTypeId[aServerId] = aPresetTypeId;
    this.curServersPresets[aServerId] = null; //--Clear
    this.getPreset(aPresetTypeId);
  }

  getCurPresetsLive(aServerId: number): TitlePresetDetail[] {
    let srvPreset = this.curServersPresets[aServerId];
    if(srvPreset) {
      return srvPreset.currentValues.titlePresetDetails
    }
    return [];
  }

  getCurPresetTitle(aServerId: number): TitlePreset {
    let srvPreset = this.curServersPresets[aServerId];
    if(srvPreset) {
      return srvPreset.currentValues
    }
    return null
  }

  getCurSavedPresets(aServerId: number): TitlePreset[] {
    let srvPreset = this.curServersPresets[aServerId];
    if(srvPreset) {
      return srvPreset.savedPresets
    }
    return [];
  }

  getPresetValuesList(aSavedPreset: TitlePreset): string {
    return aSavedPreset.titlePresetDetails.map((o)=>{
      return o.value
    }).join(', ');
  }

  loadFromSavedPreset(aServerId: number,aPreset: TitlePreset) {
    let preset : TitlePreset = this.deepCopy(aPreset) as TitlePreset;
    preset.titlePresetId = 0;
    for (const presetDet of preset.titlePresetDetails) {
      presetDet.titlePresetId = 0;
      presetDet.titlePresetDetailId = 0;
    }
    this.editCurSrvPresetLive[aServerId] = preset;
  }

  loadPresetValues(aPresetDet:TitlePresetDetail, aValue: string){
    aPresetDet.value = aValue;
  }

  savePreset(aServerId: number) {
    let preset = this.editCurSrvPresetLive[aServerId];
    let hasEmpty = false;
    for (const presetDet of preset.titlePresetDetails) {
      if(!presetDet.value.trim().length) {
        hasEmpty = true;
        break;
      }
    }
    if(!hasEmpty) {
      if(preset){
        console.log('savePreset', preset)
        this.addPreset(preset);
      }
    } else {
      this.toastService.pushAlert('Can\'t save with empty data');
    }
  }
  
  removePreset(aPresetId: number){
    let conf = window.confirm('Are you sure you want to delete this preset ?');
    if(conf) {
      this.deletePreset(aPresetId);
    }
  }

  //--------------------------------------------------------------------------

  

 

  deepCopy(aObj){
    return JSON.parse(JSON.stringify(aObj))
  }

}

import { Component, OnInit } from '@angular/core';
import { Server } from '@app/models';
import { ToastService } from '@app/services/toast.service';
import { VmixAdminApiService } from '@app/services/vmix-admin-api.service';

@Component({
  selector: 'app-admin-server',
  templateUrl: './admin-server.component.html',
  styleUrls: ['./admin-server.component.scss']
})
export class AdminServerComponent implements OnInit {
  isLoadingServers: boolean;
  servers: Server[];

  isLoadingServer: boolean;
  isSavingServer: boolean;
  isDeletingServer: boolean;
  isRefreshingServer: boolean;

  srvFilterStr: string;
  curServer: Server;

  constructor(
    private vmixAdminApiService: VmixAdminApiService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.isLoadingServers = false;
    this.isLoadingServer = false;
    this.isSavingServer = false;
    this.isDeletingServer = false;
    this.isRefreshingServer = false;

    this.srvFilterStr = '';

    this.clearServers();
    this.clearCurServer();

    this.getServers();
  }

  clearServers() {
    this.servers = [];
  }

  clearCurServer() {
    this.curServer = null;
  }

  get serversFiltred(): Server[] {
    if(this.srvFilterStr.trim().length) {
      return this.servers.filter((s)=>{
        return s.serverName.toUpperCase().includes(this.srvFilterStr.trim().toUpperCase()) ||
                s.serverAddress.toUpperCase().includes(this.srvFilterStr.trim().toUpperCase())
      })
    } else {
      return this.servers;
    }
     
  }

  private getServers() {
    this.isLoadingServers = true;
    this.clearServers();
    this.vmixAdminApiService.getServers().subscribe((servers) => {
      console.log(servers);
      if (servers) {
        this.servers = servers;
      }
      this.isLoadingServers = false;
    },
    (err) => {

    });
  }

  private getServer(aServerId: number) {
    this.isLoadingServer = true;
    this.clearCurServer();
    this.vmixAdminApiService.getServer(aServerId).subscribe((server) => {
      console.log(server);
      if (server) {
        this.curServer = server
      }
      this.isLoadingServer = false;
    },
    (err) => {

    });
  }

  private addServer(aServer: Server) {
    this.isSavingServer = true;
    this.vmixAdminApiService.addServer(aServer).subscribe((server) => {
      console.log(server);
      if (server) {
        this.servers.push(Object.assign({}, server));
        this.curServer = server;
        this.toastService.pushSucess('Server saved');
      }
      this.isSavingServer = false;
    },
    (err) => {

    });
  }

  private updateServer(aServer: Server) {
    this.isSavingServer = true;
    this.vmixAdminApiService.updateServer(aServer).subscribe((server) => {
      console.log(server);
      if (server) {
        for (let index = 0; index < this.servers.length; index++) {
          const srv = this.servers[index];
          if(srv.serverId == server.serverId) {
            this.servers.splice(index, 1, Object.assign({}, server))
            break;
          }
        }
        this.curServer = server;
        this.toastService.pushSucess('Server saved');
      }
      this.isSavingServer = false;
    },
    (err) => {

    });
  }

  private deleteServer(aServerId: number) {
    this.isDeletingServer = true;
    this.vmixAdminApiService.deleteServer(aServerId).subscribe((server) => {
      console.log(server);
      if (server) {
        for (let index = 0; index < this.servers.length; index++) {
          const srv = this.servers[index];
          if(srv.serverId == server.serverId) {
            this.servers.splice(index, 1)
            break;
          }
        }
        this.clearCurServer();
        this.toastService.pushAlert('Server deleted');
      }
      this.isDeletingServer = false;
    },
    (err) => {

    });
  }

  selectServer(aServerID: number) {
    this.getServer(aServerID);
  }

  addNewServer() {
    this.curServer = new Server();
  }

  saveCurServer() {
    if(this.curServer) {
      if(this.curServer.serverId == -1) {
        this.addServer(this.curServer);
      } else {
        this.updateServer(this.curServer);
      }
    }
  }

  deleteCurServer() {
    if(this.curServer && this.curServer.serverId != -1) {
      let conf = window.confirm('Are you sure you want to delete this server ?');
      if(conf) {
        this.deleteServer(this.curServer.serverId);
      }
    } else {
      this.clearCurServer();
    }

  }

  refreshRemoteCurServer() {
    if(!this.isRefreshingServer){
      this.isRefreshingServer = true;
      this.vmixAdminApiService.refreshShortcuts(this.curServer.serverId).subscribe((res) => {
        console.log(res);
        this.toastService.pushSucess('Remote refreshed !');
        this.isRefreshingServer = false;
      },
      (err) => {

      });
    }
  }

}

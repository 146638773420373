import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggedUser } from './models';
import { Toast } from './models/toast';
import { AuthenticationService } from './services/authentication.service';
import { ToastService } from './services/toast.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public menuIsShown: boolean;
  changePwdForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private userService: UserService,
    private authService: AuthenticationService
  ) { 
    this.hideMenu();
  }

  ngOnInit(): void {
    this.changePwdForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  get toasts(): Toast[] {
    return this.toastService.notificationList;
  }

  get isLogged(): boolean {
    return this.userService.currentUserValue ? true : false;
  }

  get user(): LoggedUser {
    return this.userService.currentUserValue;
  }

  showMenu(): void {
    this.menuIsShown = true;
  }

  hideMenu(): void {
    this.menuIsShown = false;
  }

  get f() { return this.changePwdForm.controls; }

  sumbitChangePassword() {
    // stop here if form is invalid
    if (this.changePwdForm.invalid) {
      this.toastService.pushAlert('Password and confirmation are required');
      return;
    }
    if (this.f.password.value !== this.f.confirmPassword.value) {
      this.toastService.pushAlert('Password and confirmation are different');
      return;
    }
    this.authService.changePassword(window.btoa(this.f.password.value)).subscribe((res) => {
      console.log(res);
      this.logout();
    },
    (err) => {

    });
  }

  logout(): void {
    this.userService.logout();
  }

}
